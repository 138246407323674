<template>
  <div class="analysisDetailBox" style="position: relative">
    <el-row>
      <el-button
        @click="
          () => {
            this.$router.back();
          }
        "
        >返回</el-button
      >
      <el-button type="primary" @click="handleDownloadBtn">导出</el-button>
    </el-row>
    <el-row
      style="
        text-align: right;
        margin-top: 20px;
        position: absolute;
        right: 0;
        top: 30px;
        z-index: 999;
      "
    >
      <el-form inline>
        <!-- <el-form-item class="formItemBoxStyle" label="区县">
          <el-select
            v-model="form.area"
            @change="areaChangeQuery"
            style="width: 180px"
          >
            <el-option label="全部" value=""></el-option>
            <el-option
              v-for="(v, i) in this.sysOrgAreaList"
              :key="i"
              :label="v.name"
              :value="v.sysOrgAreaId"
            ></el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item class="formItemBoxStyle" label="学校">
          <el-select
            v-model="form.school"
            @change="fetchAnalysisData"
            style="width: 180px"
          >
            <el-option label="全部" value=""></el-option>
            <el-option
              v-for="(v, i) in schoolConditionList"
              :key="i"
              :value="v.sysOrgSchoolId"
              :label="v.sysOrgSchoolName"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </el-row>
    <el-row style="margin: 20px 0">
      <el-tabs class="tabsBox" v-model="activeName" @tab-click="handleTabClick">
        <el-tab-pane
          v-for="item in subjectTypeConditionList"
          :key="item"
          :label="item | subjectFilter"
          :name="item + ''"
        >
          <!-- 表格 -->
          <el-table
            v-loading="loading"
            :data="analysisData"
            :ref="'analysisData' + item"
            class="analysisData"
            :header-cell-style="{
              'text-align': 'center',
              backgroundColor: '#dfe6ec',
            }"
            border
            :cell-style="{ 
              'text-align': 'center',
            }"
          >
            <el-table-column
              label="序号"
              type="index"
              width="50"
            ></el-table-column>
            <el-table-column
              label="考题名称"
              width="500"
              prop="testName"
            ></el-table-column>
            <el-table-column label="科目" prop="subjectType">
              <template slot-scope="scope">
                <span>{{ scope.row.subjectType | subjectFilter }}</span>
              </template>
            </el-table-column>
            <el-table-column
              label="考生数"
              prop="examineeCount"
            ></el-table-column>
            <el-table-column
              label="平均分"
              prop="averageScore"
            ></el-table-column>
            <el-table-column label="操作" type="expand" width="100">
              <template slot-scope="props">
                <el-table
                  class="testItemList"
                  :data="props.row.testItemList"
                  :header-cell-style="{
                    'text-align': 'center',
                    backgroundColor: '#EBEEF5',
                  }"
                  border
                  :cell-style="{ 'text-align': 'center' }"
                  style="width: 98%; margin: 0 auto"
                >
                  <el-table-column
                    label="序号"
                    prop="serialNumber"
                    width="50"
                  ></el-table-column>
                  <el-table-column
                    label="评分项"
                    prop="itemName"
                  ></el-table-column>
                  <el-table-column
                    label="分值"
                    width="80"
                    prop="itemNameScore"
                  ></el-table-column>
                  <el-table-column
                    label="平均分"
                    width="80"
                    prop="itemAverageScore"
                  ></el-table-column>
                  <el-table-column
                    label="失分人数"
                    sortable
                    prop="loseScoreCount"
                    width="120"
                  ></el-table-column>
                  <el-table-column
                    label="失分率"
                    sortable
                    prop="loseScoreRate"
                    width="120"
                  >
                    <template slot-scope="scope">
                      {{ scope.row.loseScoreRate }}%
                    </template>
                  </el-table-column>
                  <el-table-column
                    label="满分率"
                    sortable
                    prop="fullScoreRate"
                    width="120"
                  >
                    <template slot-scope="scope">
                      {{ scope.row.fullScoreRate }}%
                    </template>
                  </el-table-column>
                  <el-table-column
                    label="得分率"
                    sortable
                    prop="getScoreRate"
                    width="120"
                  >
                    <template slot-scope="scope">
                      {{ scope.row.getScoreRate }}%
                    </template>
                  </el-table-column>
                </el-table>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>
    </el-row>
  </div>
</template>

<script>
import {
  getScoreAnalysisDetailApi,
  downloadScoreAnalysisSummaryApi,
} from "@/api/score/scoreQuery";
import { imageAddress } from "@/utils/publicFunc";
export default {
  data() {
    return {
      examId: "",
      schoolConditionList: [],
      sysOrgAreaList: [],
      examQuestionList: [],
      subjectTypeConditionList: [],
      form: {
        area: "",
        school: "",
      },
      activeName: "",
      subjectType: "",
      analysisData: [],
      loading: false,
    };
  },
  created() {
    let {
      examId,
      schoolConditionList,
      sysOrgAreaList,
      examQuestionList,
      subjectTypeConditionList,
    } = this.$route.query;
    this.examId = examId;
    this.schoolConditionList = JSON.parse(schoolConditionList);
    // this.sysOrgAreaList = JSON.parse(sysOrgAreaList);
    this.subjectTypeConditionList = JSON.parse(subjectTypeConditionList);
    this.examQuestionList = JSON.parse(examQuestionList);
    this.activeName = this.subjectTypeConditionList[0] + "";
    this.subjectType = this.subjectTypeConditionList[0];

    this.fetchAnalysisData();
  },
  computed: {
    tempSchoolList() {
      if (!this.form.area) {
        return [];
      } else {
        return this.schoolConditionList.filter(
          (it) => it.sysOrgAreaId === this.form.area
        );
      }
    },
  },
  methods: {
    fetchAnalysisData() {
      let param = {
        subjectType: this.subjectType,
      };
      if (this.form.area) {
        param = {
          ...param,
          sysOrgAreaId: this.form.area,
        };
      }
      if (this.form.school) {
        param = {
          ...param,
          sysOrgSchoolId: this.form.school,
        };
      }
      this.loading = true;
      getScoreAnalysisDetailApi(this.examId, param).then((res) => {
        if (res.success) {
          this.analysisData = res.data.filter(
            (it) => it.subjectType == this.subjectType
          );
          this.$nextTick(() => {
            let keyWord = `analysisData${this.subjectType}`;
            this.$refs[keyWord][0].toggleRowExpansion(
              this.analysisData[0],
              true
            );
            // console.log(this.analysisData[0]);
          });
        } else {
          this.$message.error(res.msg);
          return;
        }
        this.loading = false;
      });
    },
    // 切换学科
    handleTabClick() {
      this.subjectType = +this.activeName;
      this.fetchAnalysisData();
    },
    // 切换区县或学校
    areaChangeQuery(val) {
      this.form.school = "";
      this.fetchAnalysisData();
    },
    handleDownloadBtn() {
      downloadScoreAnalysisSummaryApi(this.examId).then((res) => {
        if(res.success) {
          window.open(imageAddress(res.data));
        } else {
          this.$message.error(res.msg)
          return
        }
      })
    }
  },
  filters: {
    //学科过滤
    subjectFilter(val) {
      switch (val) {
        case 1:
          return `物理`;
        case 2:
          return `生物`;
        case 3:
          return `化学`;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .tabsBox {
  .el-tabs__item {
    font-size: 18px;
  }
}
::v-deep .testItemList {
  .cell {
    height: 34px;
    line-height: 34px;
  }
}
</style>
