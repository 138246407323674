var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"analysisDetailBox",staticStyle:{"position":"relative"}},[_c('el-row',[_c('el-button',{on:{"click":() => {
          this.$router.back();
        }}},[_vm._v("返回")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.handleDownloadBtn}},[_vm._v("导出")])],1),_c('el-row',{staticStyle:{"text-align":"right","margin-top":"20px","position":"absolute","right":"0","top":"30px","z-index":"999"}},[_c('el-form',{attrs:{"inline":""}},[_c('el-form-item',{staticClass:"formItemBoxStyle",attrs:{"label":"学校"}},[_c('el-select',{staticStyle:{"width":"180px"},on:{"change":_vm.fetchAnalysisData},model:{value:(_vm.form.school),callback:function ($$v) {_vm.$set(_vm.form, "school", $$v)},expression:"form.school"}},[_c('el-option',{attrs:{"label":"全部","value":""}}),_vm._l((_vm.schoolConditionList),function(v,i){return _c('el-option',{key:i,attrs:{"value":v.sysOrgSchoolId,"label":v.sysOrgSchoolName}})})],2)],1)],1)],1),_c('el-row',{staticStyle:{"margin":"20px 0"}},[_c('el-tabs',{staticClass:"tabsBox",on:{"tab-click":_vm.handleTabClick},model:{value:(_vm.activeName),callback:function ($$v) {_vm.activeName=$$v},expression:"activeName"}},_vm._l((_vm.subjectTypeConditionList),function(item){return _c('el-tab-pane',{key:item,attrs:{"label":_vm._f("subjectFilter")(item),"name":item + ''}},[_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],ref:'analysisData' + item,refInFor:true,staticClass:"analysisData",attrs:{"data":_vm.analysisData,"header-cell-style":{
            'text-align': 'center',
            backgroundColor: '#dfe6ec',
          },"border":"","cell-style":{ 
            'text-align': 'center',
          }}},[_c('el-table-column',{attrs:{"label":"序号","type":"index","width":"50"}}),_c('el-table-column',{attrs:{"label":"考题名称","width":"500","prop":"testName"}}),_c('el-table-column',{attrs:{"label":"科目","prop":"subjectType"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',[_vm._v(_vm._s(_vm._f("subjectFilter")(scope.row.subjectType)))])]}}],null,true)}),_c('el-table-column',{attrs:{"label":"考生数","prop":"examineeCount"}}),_c('el-table-column',{attrs:{"label":"平均分","prop":"averageScore"}}),_c('el-table-column',{attrs:{"label":"操作","type":"expand","width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('el-table',{staticClass:"testItemList",staticStyle:{"width":"98%","margin":"0 auto"},attrs:{"data":props.row.testItemList,"header-cell-style":{
                  'text-align': 'center',
                  backgroundColor: '#EBEEF5',
                },"border":"","cell-style":{ 'text-align': 'center' }}},[_c('el-table-column',{attrs:{"label":"序号","prop":"serialNumber","width":"50"}}),_c('el-table-column',{attrs:{"label":"评分项","prop":"itemName"}}),_c('el-table-column',{attrs:{"label":"分值","width":"80","prop":"itemNameScore"}}),_c('el-table-column',{attrs:{"label":"平均分","width":"80","prop":"itemAverageScore"}}),_c('el-table-column',{attrs:{"label":"失分人数","sortable":"","prop":"loseScoreCount","width":"120"}}),_c('el-table-column',{attrs:{"label":"失分率","sortable":"","prop":"loseScoreRate","width":"120"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(scope.row.loseScoreRate)+"% ")]}}],null,true)}),_c('el-table-column',{attrs:{"label":"满分率","sortable":"","prop":"fullScoreRate","width":"120"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(scope.row.fullScoreRate)+"% ")]}}],null,true)}),_c('el-table-column',{attrs:{"label":"得分率","sortable":"","prop":"getScoreRate","width":"120"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(scope.row.getScoreRate)+"% ")]}}],null,true)})],1)]}}],null,true)})],1)],1)}),1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }